<template>
  <div class="withdrawal">
    <van-overlay :show="tipsShow" z-index="1000">
      <div class="wrapper" @click.stop="tipsShow = false">
        <div class="block">
          <h3 class="title">常见问题</h3>
          <div class="content">
            <p>1、提现申请提交后，预计在1个工作日到账，请注意查收。</p>
            <p>
              2、目前提现到银行卡不收取手续费，如需收取，提现后手续费可在钱包余额明细中查看。
            </p>
            <p>
              3、若提现失败，或因提现到账银行卡所属发行卡不支持此类交易，请更换银行卡后重新申请提现。
            </p>
            <van-button round color="#009A4D" block @click="tipsShow = false"
              >知道了</van-button
            >
          </div>
        </div>
      </div>
    </van-overlay>
    <main>
      <van-cell title="到账银行卡" is-link :center="true" @click="show = true">
        <template #default>
          <p v-if="!selectedBank.bankAcctNo">请选择银行卡</p>
          <p v-else>
            <img :src="getBankLogo(selectedBank.bankCode2)" alt="" />{{
              selectedBank.name
            }}
          </p>
        </template>
      </van-cell>
      <div class="content">
        <p class="text">提现金额</p>
        <div class="num">
          <b>￥</b
          ><input
            @input="amountInput"
            type="number"
            v-model="amount"
            placeholder="请输入提现金额"
          />
        </div>
        <p class="text withdrawable">
          可提现金额￥{{
            withdrawable ? withdrawable.toFixed(2) : withdrawable
          }}，<b @click="amount = withdrawable.toFixed(2)">全部提现</b>
        </p>
        <!-- <p class="text">1、工作日提现最晚次日到账，调节假日顺延；</p> -->
        <van-button
          color="#009A4D"
          type="primary"
          round
          block
          @click="confirmedWithdrawal"
          style="margin-top: 0.6rem"
          >确认提现</van-button
        >
        <div class="problem">
          <span @click="tipsShow = true">常见问题</span>
        </div>
      </div>
    </main>

    <van-action-sheet v-model="show" title="选择银行卡" class="bank_action">
      <van-cell
        v-for="item in bank"
        :key="item.bankAcctNo"
        :clickable="true"
        @click="selectBank(item)"
      >
        <template #title>
          <img :src="getBankLogo(item.bankCode2)" alt="" />
        </template>
        <template #default>
          <p>{{ item.name }}</p>
        </template>
        <template #right-icon>
          <img
            src="@/assets/images/gou_icon.png"
            alt=""
            v-show="selectedBank.bankAcctNo === item.bankAcctNo"
          />
        </template>
      </van-cell>
      <van-cell :clickable="true" url="#/userData/bankCard">
        <template #title>
          <svg-icon icon-class="tj_icon" />
        </template>
        <template #default>
          <p>添加或管理银行卡</p>
        </template>
      </van-cell>
    </van-action-sheet>
    <van-overlay z-index="1000" :show="passwordShow" class-name="password">
      <div class="wrapper" :style="showKeyboard ? 'padding-bottom:2.2rem' : ''">
        <div class="box">
          <div class="title">
            <p>请输入支付密码</p>
            <svg-icon icon-class="close" @click="passwordShow = false" />
          </div>
          <div class="content">
            <p>提现</p>
            <h3><span>￥</span>{{ amount }}</h3>
            <!-- <p class="tips">
              提现手续费：非广州银行卡需扣除提现手续费，以银行卡到账金额为准。
            </p> -->
            <van-password-input
              :value="value"
              :focused="showKeyboard"
              @focus="showKeyboard = true"
            />
            <!-- 数字键盘 -->
            <van-number-keyboard
              v-model="value"
              :show="showKeyboard"
              @blur="showKeyboard = false"
            />
          </div>
          <router-link to="">忘记支付密码？</router-link>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "withdrawal",
  data() {
    return {
      tipsShow: false,
      show: false,
      bank: [],
      withdrawable: 0,
      amount: 0,
      selectedBank: {},
      passwordShow: false,
      value: "",
      showKeyboard: false,
    };
  },
  computed: {
    ...mapState("user", ["electronicAcct", "bankCard", "isLogin"]),
  },
  watch: {
    value(val) {
      if (val.length === 6) {
        this.withdrawalCreate();
      }
    },
  },
  async created() {
    if (!this.isLogin) return;
    await this.$store.dispatch("user/queryElectronicAcct");

    this.electronicAcct.forEach((e) => {
      this.withdrawable += e.settledAmount + e.accountAmount;
    });
    // this.withdrawable = 7.5;
    this.withdrawable = this.withdrawable / 100;
    console.log(this.bankCard);
    this.bankCard.forEach((e) => {
      // bankAcctNo
      e.name = `${e.bankName}（${e.bankAcctNo.slice(-4)}）`;
      this.bank.push(e);
    });
  },
  methods: {
    selectBank(bank) {
      this.show = false;
      if (bank.bankAcctNo === this.selectedBank.bankAcctNo) {
        this.selectedBank = {};
        return;
      }
      this.selectedBank = bank;
      console.log(this.selectedBank);
    },
    amountInput() {
      if (this.amount > this.withdrawable) {
        this.amount = this.withdrawable.toFixed(2);
      } else if (this.amount < 0) {
        this.amount = 0;
      }
    },
    confirmedWithdrawal() {
      if (Object.keys(this.selectedBank).length === 0) {
        this.Toast.fail("请输入要提现的银行");
        return;
      }
      if (this.amount <= 0) {
        this.Toast.fail("请输入要提现的金额");
        return;
      }
      this.passwordShow = true;
    },
    async withdrawalCreate() {
      var loadingToast = this.Toast.loading({
        message: "提现中",
        forbidClick: true,
        duration: 0,
      });

      await this.$API.user
        .withdrawalCreate({
          object: {
            amount: Math.round(this.amount * 100), //金额
            balanceAcctId: this.electronicAcct[0].balanceAcctId, //电子账簿ID
            bankAcctNo: this.selectedBank.bankAcctNo, //提现目标银行
            bankAcctType: "1", //目标银行账号类型 1-对私,2-对公 非绑定用户时必填
            bankBranchCode: this.selectedBank.bankCode, //开户银行联行号 非绑定用户时必填
            bankCode: this.selectedBank.bankCode, //开户银行编号 非绑定用户时必填
            bankMemo: "", //银行附言
            bankName: this.selectedBank.bankName, //提现目标银行名字
            bankType: 2, //处理支付对象,1-通联,2-广州银行,3-江苏银行
            businessType: "1", //业务类型 1-提现 2-转账
            mobileNumber: this.electronicAcct[0].mobileNumber, //手机号
            name: this.selectedBank.settleAcctName, //开户名称 非绑定用户时必填
            password: this.value, //交易授权码
            remark: "", //备注
            serviceFee: 0, //平台手续费
          },
        })

        .then((res) => {
          if (res.data.code !== "SUCCESS") throw Error(res.data.msg);
          console.log(res.data);
          loadingToast.clear();
          this.$router.replace(
            `withdrawalStatus?outOrderNo=${res.data.data.outOrderNo}`
          );
        })
        .catch((e) => {
          console.log(e.message);
          loadingToast.clear();
          this.Toast.fail(e.message);
          this.value = "";
        });
    },
    getBankLogo(code) {
      let img = "qt";
      switch (code) {
        case "COMM":
          img = "COMM";
          break;
        case "ABC":
          img = "ABC";
          break;
        case "CCB":
          img = "CCB";
          break;
        case "CITIC":
          img = "CITIC";
          break;
        case "CMB":
          img = "CMB";
          break;
        case "GCB":
          img = "GCB";
          break;
        case "ICBC":
          img = "ICBC";
          break;
        case "PSBC":
          img = "PSBC";
          break;
        case "SPABANK":
          img = "SPABANK";
          break;
        case "SPDB":
          img = "SPDB";
          break;
        default:
          break;
      }
      return `${require(`@/assets/images/bank/${img}_logo.png`)}`;
    },
    // (selectedBank = item), (show = false)
  },
};
</script>

<style lang="scss" scoped>
.withdrawal {
  background: #f5f5f5;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: 46px;
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    .block {
      width: 90%;
      border-radius: 20px;
      background-color: #fff;
      padding: 24px 20px;
      position: relative;
      .title {
        font-size: 18px;
        text-align: center;
        margin-bottom: 18px;
      }
      .content {
        p {
          margin-bottom: 16px;
          color: #666;
        }
        .van-button {
          margin: 0 auto;
          width: auto;
          padding: 0 42px;
        }
      }
    }
  }
  main {
    padding: 15px;
    > .van-cell {
      background-color: #f5f5f5;
      padding: 25px 0 40px 12px;
      .van-cell__title {
        flex: none;
        color: #666;
      }
      .van-cell__value {
        text-align: center;
        color: #333;
        p {
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 16px;
            height: 16px;
            margin-right: 8px;
          }
        }
      }
      .van-icon {
        font-size: 12px;
      }
    }
    .content {
      padding: 16px 12px;
      background: #fff;
      border-radius: 5px;
      .text {
        color: #666666;
      }
      .num {
        margin-top: 68px;
        padding-bottom: 6px;
        border-bottom: 1px solid #f2f2f2;
        display: flex;
        font-size: 24px;
        line-height: 32px;

        input {
          padding-left: 18px;
          border: none;
          font-weight: bold;
          width: 100%;
        }
        ::-webkit-input-placeholder {
          font-size: 14px;
          color: #cccccc;
          font-weight: normal;
        }
      }
      .withdrawable {
        font-size: 16px;
        margin: 18px 0 36px;
        b {
          color: #009a4d;
        }
      }
      .problem {
        color: #009a4d;
        text-align: center;
        margin: 20px 0 0;
      }
    }
  }
  .bank_action {
    padding-bottom: 30px;
    .van-action-sheet__header {
      padding: 20px 0;
      margin: 0 15px;
      line-height: 24px;
      border-bottom: 1px solid #f2f2f2;
      .van-icon {
        top: 20px;
        color: #878484;
      }
    }
    .van-action-sheet__content {
      .van-cell {
        padding: 20px 15px;
        line-height: 22px;
        img {
          width: 22px;
          height: 22px;
        }
        .van-cell__title {
          flex: none;
          margin-right: 24px;
          height: 22px;
        }
        .van-cell__value {
          text-align: start;
          font-size: 16px;
          color: #333333;
        }
      }
    }
  }
  .password {
    .wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      transition: 0.4s all;
      .box {
        width: 80%;
        padding: 22px 18px;
        background-color: #fff;
        border-radius: 5px;
        text-align: center;

        .title {
          position: relative;
          font-size: 17px;
          line-height: 24px;
          margin-bottom: 28px;
          .svg-icon {
            position: absolute;
            top: 4px;
            right: 0;
            color: #878484;
            font-size: 14px;
          }
        }
        .content {
          h3 {
            font-size: 24px;
            font-weight: bold;
            line-height: 34px;
            padding-bottom: 16px;
            border-bottom: 1px solid #f2f2f2;
            span {
              font-size: 17px;
            }
          }
          .tips {
            margin: 12px 0 16px;
            font-size: 12px;
            color: #999;
            text-align: start;
          }
          .van-password-input {
            margin: 0;
            [class*="van-hairline"]::after {
              border-color: #e4e4e4;
            }
          }
        }
        a {
          display: block;
          text-align: start;
          margin-top: 8px;
          color: #009a4d;
        }
      }
    }
  }
}
</style>
